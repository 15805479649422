import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Radio, message } from 'antd';
import { assignCourse } from '../../../http/modules/organization'
import './index.scss'

// table 格式
const columns1 = [
    {
        title: '用户名',
        dataIndex: 'loginName',
        key: 'loginName',
        className: 'table'
    },
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        className: 'table'
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
        className: 'table'
    },
];

// table 格式
const columns2 = [
    {
        title: '组别',
        dataIndex: 'category',
        key: 'category',
        className: 'table'
    },
    {
        title: '用户名',
        dataIndex: 'loginName',
        key: 'loginName',
        className: 'table'
    },
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        className: 'table'
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
        className: 'table'
    },
];

export default function Allot(props) {

    const [form] = Form.useForm();

    const { open, setOpen, selectedRowKeys, setSelectedRowKeys,
        onListCourse, current, categorySelect, searchName, rate, assignState } = props
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 分配确定
    const onFinish = async (values) => {
        try {
            const res = await assignCourse({
                "courseUUIDList": selectedRowKeys,
                "groupOrg": values.groupOrg,
                "groupExpert": values.groupExpert
            });
            if (res.code == 0) {
                message.success('分配成功')
                setSelectedRowKeys([])
                setOpen(false);
                onListCourse(current, categorySelect, searchName)
                form.resetFields();
                setConfirmLoading(false);
            } else {
                message.error(res.msg)
            }
        } catch (error) {

        }
    }

    // 分配取消
    const handleCancel = () => {
        form.resetFields();
        setOpen(false);
    };

    return (

        <Modal
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
        >
            <div className='allot'>
                <header>
                    <p></p>
                    分配课程
                </header>
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item label="企业家组别" name='groupOrg' >
                        <Radio.Group>
                            <Radio style={{ fontSize: "18px" }} key={'企业家A组'} value={'企业家A组'}> {'企业家A组'} </Radio>
                            <Radio style={{ fontSize: "18px" }} key={'企业家B组'} value={'企业家B组'}> {'企业家B组'} </Radio>
                            <Radio style={{ fontSize: "18px" }} key={'企业家C组'} value={'企业家C组'}> {'企业家C组'} </Radio>
                            <Radio style={{ fontSize: "18px" }} key={'企业家D组'} value={'企业家D组'}> {'企业家D组'} </Radio>
                        </Radio.Group>

                    </Form.Item>

                    <Form.Item label="专家组别" name='groupExpert' >
                        <Radio.Group >
                            <Radio style={{ fontSize: "18px" }} key={'专家A组'} value={'专家A组'}> {'专家A组'} </Radio>
                            <Radio style={{ fontSize: "18px" }} key={'专家B组'} value={'专家B组'}> {'专家B组'} </Radio>
                            <Radio style={{ fontSize: "18px" }} key={'专家C组'} value={'专家C组'}> {'专家C组'} </Radio>
                            <Radio style={{ fontSize: "18px" }} key={'专家D组'} value={'专家D组'}> {'专家D组'} </Radio>
                        </Radio.Group>

                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 3,
                            span: 10,
                        }}>
                        <Button type="primary" htmlType="submit">
                            确定
                        </Button>
                        <Button htmlType="button" onClick={handleCancel}>
                            取消
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}
