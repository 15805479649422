import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Checkbox,
  message,
} from 'antd';
import { courseCategoryList, addWinCourse } from '../../../http/modules/organization'
import Oss from '../../../components/ossUpload/oss'
import './index.scss'
const { Option } = Select;


export default function ApplyAdd(props) {

  const [form] = Form.useForm();

  const { setOpen } = props

  // 课程类型
  const [radioValue, setRadioValue] = useState('标准课程');
  // 省
  const [province, setProvince] = useState('Win');
  // 是否可以提交
  const [disabled, setDisabled] = useState(true)
  // 课程类别
  const [categoryList, setCategoryList] = useState()
  // 主课程Url
  const [mainCourseUrl, setMainCourseUrl] = useState()
  // 主课程文件名称
  const [mainCourseName, setMainCourseName] = useState()
  // 说课Url
  const [subCourseUrl, setSubCourseUrl] = useState()
  // 说课文件名称
  const [subCourseName, setSubCourseName] = useState()
  // 报名表Url 
  const [formUrl, setFormUrl] = useState()
  // 报名表文件名称 
  const [formName, setFormName] = useState()
  // 课程类别
  const [kclb, setKclb] = useState()
  // 课程名称
  const [title, setTitle] = useState()
  // 参赛者id
  const [playerID, setPlayerID] = useState()
  // 附件
  const [fuJian, setFuJian] = useState([])

  const [minTime, setMinTime] = useState(0)
  const [maxTime, setMaxTime] = useState(3000)

  // 附件列表
  const [fujianData, setfujianData] = useState()

  const onFuJian = (name, url) => {
    setFuJian(fuJian => [...fuJian, {
      "name": name,
      "url": url
    }]);
  }

  const onFinish = async (values) => {
    /* if (!formUrl) {
      message.error('请上传申报表')
      return
    } */
    let type
    radioValue == '标准课程' ? type = 'standard' : type = 'short'
    try {
      const res = await addWinCourse({
        'playerName': playerID,
        "title": title,
        "category": kclb,
        "typ": type,
        "mainCourseUrl": mainCourseUrl,
        "mainCourseName": mainCourseName,
        "subCourseUrl": subCourseUrl,
        "subCourseName": subCourseName,
        "formUrl": formUrl||'',
        "formName": formName||'',
        "attachmentList": fuJian||''
      })
      if (res.code == 0) {
        message.success('上传成功')
        form.resetFields();
        setOpen(false)
      } else {
        message.error(`添加失败${res.msg}`)
      }
    } catch (error) {
      message.error(error.response?.data?.msg);
    }
  };

  //课程类别
  const onCourseCategoryList = async () => {
    const res = await courseCategoryList({})
    setCategoryList(res.data?.list)
  }

  // 删除
/*   const remove = (id) => {
    let a = [...fuJian]
    a?.splice(a.findIndex(item => item.name == id), 1)
    setFuJian(a)
  } */

  useEffect(() => {
    onCourseCategoryList()
  }, [])

  return (
    <div className='applyAdd'>
      <header>
        <p></p>
        新增申报
      </header>

      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        layout="horizontal"
        form={form}
      >

        <Form.Item
          name="gender"
          label="授课人"
          rules={[
            {
              required: true,
              message: '请选择授课人!'
            },
          ]}
        >
          <Input onChange={(e) => setPlayerID(e.target.value)} />
        </Form.Item>

        <Form.Item label="课程名称" name='title' rules={[
          {
            required: true,
            message: '请填写课程名称!'
          },]}>
          <Input onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>

        <Form.Item label="课程类别" /* name='category' */ rules={[
          {
            required: true,
            message: '请选择课程类别!'
          },]}>
          <Radio.Group onChange={((e) => setKclb(e.target.value))}>
            {categoryList?.map((item) => {
              return <Radio style={{ fontSize: "18px" }} key={item.name} value={item.name}> {item.name} </Radio>
            })}
          </Radio.Group>
        </Form.Item>

        <Form.Item label="授课类型" name='typ' initialValue={'标准课程'} rules={[
          {
            required: true,
            message: '请选择授课类型!'
          },]}>
          <Radio.Group onChange={(e) => {
            if (e.target.value == '标准课程') {
              setMinTime(0)
              setMaxTime(3000)
              setRadioValue(e.target.value);
            } else {
              setMinTime(0)
              setMaxTime(1020)
              setRadioValue(e.target.value);
            }
          }} defaultChecked={true} value={radioValue}>
            <Radio style={{ fontSize: '18px' }} value="标准课程"> 标准课程（45分钟） </Radio>
            <Radio style={{ fontSize: '18px' }} value="短课程"> 短课程（15分钟） </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="上传视频" valuePropName="fileList">
          <>
            <Oss ext={'mp4'} /* minTime={minTime} maxTime={maxTime} */ url={setMainCourseUrl} province={province} kclb={kclb} title={title} video={'video1'} Name={setMainCourseName} buttonName={`支持格式MP4--点击上传${radioValue}`} />
           {/*  {radioValue == '标准课程' ? <Oss minTime={0} ext={'mp4'} maxTime={720} url={setSubCourseUrl} province={province} kclb={kclb} title={title} video={'video2'} Name={setSubCourseName} buttonName='支持格式MP4--时长(10分钟)--说课视频' /> : ''} */}
          </>
        </Form.Item>

        {/* <Form.Item label="上传附件" valuePropName="fileList">
          <>
            <Oss url={setFormUrl} province={province} kclb={kclb} title={title} video={'baoming'} Name={setFormName} buttonName='上传申报表' />
            <div className='fujian'>
              {fuJian?.map((item) => {
                return <p key={item.url}>
                  <span title={item.name} style={{ width: '70%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>名称:{item.name}</span> <span style={{ color: 'red' }}> <a href={item.url?.split("?")[0]}>附件下载</a> <span
                    style={{ cursor: 'pointer' }} onClick={() => remove(item.name)}>X</span></span>
                </p>
              })}
              <Oss fuJian={fuJian} setfujianData={setfujianData} onFuJian={onFuJian} province={province} kclb={kclb} title={title} buttonName='教辅工具' />
            </div>
          </>
        </Form.Item> */}

        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 4, span: 22 }}>
          <Checkbox style={{ fontSize: '16px' }} onChange={(value) => setDisabled(!value.target.checked)}>本人承诺该课程可免费、公开向社会发布使用，视频课程不侵犯第三方知识产权，如有任何知识产权纠纷，由报送人承担全部责任。</Checkbox>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 12,
            span: 12,
          }}>
          <Button type="primary" htmlType="submit" disabled={disabled} onClick={onFinish} className='queDing' title={disabled ? '请先同意承诺' : ''}>
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
