import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Radio } from 'antd';
import { createExpert, auditUserInfo, editExpert } from "../../../http/modules/organization";
import './index.scss'

export default function Index(props) {

  const [form] = Form.useForm();
  const { setPlayerId, playerId, OnListPlayer, name, categorySelect, searchName } = props

  // 详情数据
  const [info, setInfo] = useState({})

  const onFinish = async (values) => {
    if (playerId != 'xinjian') {
      const res = await editExpert({
        "name": info.name,
        "loginName": info.loginName,
        "phone": info.phone,
        "orgName": info.orgName,
        groupExpert: info.groupFlag,
        userUUID: info.uuid
      });
      if (res.code == 0) {
        message.success('修改成功')
        OnListPlayer(1, searchName, categorySelect)
        form.resetFields();
        onReset()
      }
      else {
        message.error(`修改失败${res.msg}`)
      }
    } else {
      try {
        const res = await createExpert({
          "name": values.name,
          "loginName": values.loginName,
          "phone": values.phone,
          "orgName": values.orgName,
          "groupExpert": info.groupFlag,
        });
        if (res.code == 0) {
          message.success('添加成功')
          form.resetFields();
          OnListPlayer(1, '', '')
          onReset()
        }
        else {
          message.error(`添加失败${res.msg}`)
        }
      } catch (error) {
        message.error('添加失败')
        console.log(error)
      }
    }
  };

  // 详情
  const onAuditUserInfo = async () => {
    try {
      const res = await auditUserInfo({
        "userUUID": playerId
      });
      if (res.code == 0) {
        setInfo(res.data)
        return res.data
      }
      else {
        message.error('详情获取失败')
      }
    } catch (error) {

    }
  };

  // 取消
  const onReset = () => {
    setPlayerId('')
    form.resetFields();
  };

  useEffect(() => {
    if (playerId != 'xinjian') {
      onAuditUserInfo()
    }
  }, [])

  return (
    <div className='xinJian'>
      <header>
        <p></p>
        {name}
      </header>
      {info ? <Form
        form={form}
        name="basic"
        labelCol={{
          span: 3,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >

        <Form.Item
          label="用户名"
          name="loginName"
        >
          <Input placeholder={info.loginName} value={info.loginName} onChange={e => {
            setInfo({ ...info, loginName: e.target.value })
          }} />
        </Form.Item>

        <Form.Item
          label="姓名"
          name="name"
        >
          <Input value={info.name} placeholder={info.name} onChange={e => {
            setInfo({ ...info, name: e.target.value })
          }} />
        </Form.Item>

        <Form.Item
          label="手机"
          name="phone"
        >
          <Input placeholder={info.phone} value={info.phone} onChange={e => {
            setInfo({ ...info, phone: e.target.value })
          }} />
        </Form.Item>

        <Form.Item
          label="单位"
          name="orgName"
        >
          <Input placeholder={info.orgName} value={info.orgName} onChange={e => {
            setInfo({ ...info, orgName: e.target.value })
          }} />
        </Form.Item>

        <Form.Item label="组别" name='groupExpert' >
          <Radio.Group onChange={((e) => setInfo({ ...info, groupFlag: e.target.value }))} value={info.groupFlag}>
            <Radio style={{ fontSize: "18px" }} key={'专家A组'} value={'专家A组'}> {'专家A组'} </Radio>
            <Radio style={{ fontSize: "18px" }} key={'专家B组'} value={'专家B组'}> {'专家B组'} </Radio>
            <Radio style={{ fontSize: "18px" }} key={'专家C组'} value={'专家C组'}> {'专家C组'} </Radio>
            <Radio style={{ fontSize: "18px" }} key={'专家D组'} value={'专家D组'}> {'专家D组'} </Radio>
          </Radio.Group>
          <span style={{display:'none'}}>{info?.groupFlag}</span>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 3,
            span: 10,
          }}>
          <Button type="primary" htmlType="submit">
            确定
          </Button>
          <Button htmlType="button" onClick={onReset}>
            取消
          </Button>
        </Form.Item>
      </Form> : ''}
    </div>
  )
}
