import React, { useState, useEffect } from 'react';
import OSS from 'ali-oss';
import { Progress, message } from 'antd';
import img from '../../img/shangChuan.svg'
import './oss.scss'

const UploadComponent = (props) => {
  const [selectedFile, setSelectedFile] = useState();

  const { onFuJian, ext, minTime, maxTime, fuJian } = props;

  const [arr, setArr] = useState([])

  // 视频时长
  const [time, setTime] = useState()
  // 视频格式
  const [format, setFormat] = useState()

  //进度条

  const [percent, setPercent] = useState(0)

  const handleFileChange = (event) => {
    // if(selectedFile){
    //   message.error('一个上传一个')
    //   return
    // }

    if (props.buttonName == '请先删除') {
      message.error('请先删除原文件')
    } else {
      const url = event.target.value
      const index = url.lastIndexOf(".");
      const ext = url.substr(index + 1);
      setFormat(ext)
      setSelectedFile(event.target.files[0]);


      /* let duration = 0;  
      if (event.target.files[0]?.type.indexOf('video') > -1 || event.target.files[0]?.type.indexOf('audio') > -1) {
          const url = URL.createObjectURL(event.target.files[0]);
          const filelement = new Audio(url);
          filelement.addEventListener("loadedmetadata", function (_event) {
            duration = filelement.duration;
            setTime(duration)
  
            if (minTime < duration && duration < maxTime) {
              setSelectedFile(event.target.files[0]);
            } else {
  
              var file = document.getElementById('fileupload');
              file.value = '';
  
              message.error('请注意视频时长')
            }
          });
        } else {
          setSelectedFile(event.target.files[0]);
        } */
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleUpload()
    }
  }, [selectedFile])

  const handleUpload = async () => {

    var file = document.getElementById('fileupload');


    if (!props.title) {
      message.error('请先填写课程名称')
      file.value = '';
      return
    }
    if (!props.kclb) {
      message.error('请先选择课程类别')
      file.value = '';
      return
    }

    if (ext && format != ext) {
      return message.error('请注意文件格式')
    }

    if (selectedFile) {
      setArr([selectedFile?.name])
      const client = new OSS({
        region: 'oss-cn-beijing', // OSS 区域
        accessKeyId: 'LTAI5tHyDauupprtpWM5ciSY',
        accessKeySecret: 'gNP2VMmYUAZQVHSY11LbbSafIjiMTL',
        bucket: 'zjtxpy',
      });

      // 生成一个唯一的文件名，可以根据需要进行更改
      const uniqueFileName = props.title == '*' ? `qiyeweike2024/${props.province}/${props.kclb}/${selectedFile?.name}` : `qiyeweike2024/${props.province}/${props.kclb}/${props.title}/${selectedFile?.name}`;

      try {
        // 初始化分片上传
        const result = await client.multipartUpload(uniqueFileName, selectedFile, {
          progress: (p) => {
            let number = parseInt(p * 100)
            setPercent(number)
          },
        });

        if (props.buttonName == '教辅工具') {
          onFuJian(selectedFile.name, result?.res?.requestUrls[0])
        } else {
          props.url(result?.res?.requestUrls[0].split("?")[0])
          props.Name(selectedFile.name)
        }
        message.success('资料上传成功')
        setSelectedFile('')
      } catch (e) {
        message.error('上传失败')
      }
    } else {
      message.error('请先选择要上传的文件')
    }
  };

  const number = () => {
    message.error('只能上传5个教辅工具')
  }

  return (
    <div>
      <div className='oss'>

        <div className='xuanZe' style={{ backgroundImage: `url(${img})` }}>
          <input type="file" id="fileupload" onChange={fuJian?.length == 5 ? number : handleFileChange} />
        </div>

        <div>
          <button>{props.buttonName}</button>
          {props.buttonName == '上传申报表' ? <button> <a href="https://zjtxpy.oss-cn-beijing.aliyuncs.com/qiyeweike/www/%E8%AF%BE%E7%A8%8B%E7%94%B3%E6%8A%A5%E8%A1%A8.doc">下载申报表</a></button> : ''}
        </div>

      </div>

      {
        arr?.map((item, index) => {
          return <div className='ming' key={index}>
            <p title={item} key={index}>{item}</p>
            {arr.length ? <Progress percent={percent} /> : ''}
          </div>
        })
      }
    </div>
  );
};

export default UploadComponent;
