import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Radio } from 'antd';
import { createOrg, auditUserInfo, editOrg } from "../../../http/modules/organization";
import './index.scss'

export default function Index(props) {

  const [form] = Form.useForm();
  const { setPlayerId, playerId, OnListPlayer, name } = props

  // 详情数据
  const [info, setInfo] = useState({})

  const onFinish = async (values) => {
    if (playerId != 'xinjian') {
      const res = await editOrg({
        "name": info.name,
        "loginName": info.loginName,
        "phone": info.phone,
        "orgName": info.orgName,
        userUUID: info.uuid,
        "groupOrg": info.groupFlag
      });
      if (res.code == 0) {
        message.success('修改成功')
        form.resetFields();
        OnListPlayer(1, '', '')
        onReset()
      }
      else {
        message.error(`修改失败${res.msg}`)
      }
    } else {
      const res = await createOrg({
        "name": values.name,
        "loginName": values.loginName,
        "phone": values.phone,
        "orgName": values.orgName,
        "groupOrg": info.groupFlag
      });
      if (res.code == 0) {
        message.success('添加成功')
        form.resetFields();
        OnListPlayer(1, '', '')
        onReset()
      }
      else {
        message.error(`添加失败${res.msg}`)
      }
    }
  };

  // 详情
  const onAuditUserInfo = async () => {
    try {
      const res = await auditUserInfo({
        "userUUID": playerId
      });
      if (res.code == 0) {
        setInfo(res.data)
        return res.data
      }
      else {
        message.error('详情获取失败')
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    if (playerId != 'xinjian') {
      onAuditUserInfo()
    }
  }, [])

  // 取消
  const onReset = () => {
    setPlayerId('')
    form.resetFields();
  };

  return (
    <div className='entrepreneurXinJian'>
      <header>
        <p></p>
        {name}
      </header>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 3,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >

        <Form.Item
          label="用户名"
          name="loginName"
        >
          <Input placeholder={info.loginName} value={info.loginName} onChange={e => {
            setInfo({ ...info, loginName: e.target.value })
          }} />
        </Form.Item>

        <Form.Item
          label="姓名"
          name="name"
        >
          <Input value={info.name} placeholder={info.name} onChange={e => {
            setInfo({ ...info, name: e.target.value })
          }} />
        </Form.Item>

        <Form.Item
          label="手机"
          name="phone"
        >
          <Input placeholder={info.phone} value={info.phone} onChange={e => {
            setInfo({ ...info, phone: e.target.value })
          }} />
        </Form.Item>

        <Form.Item
          label="单位"
          name="orgName"
        >
          <Input placeholder={info.orgName} value={info.orgName} onChange={e => {
            setInfo({ ...info, orgName: e.target.value })
          }} />
        </Form.Item>

        <Form.Item label="组别" name='groupOrg'>
          <Radio.Group value={info?.groupFlag} onChange={((e) => setInfo({ ...info, groupFlag: e.target.value }))}>
            <Radio style={{ fontSize: "18px" }} key={'企业家A组'} value={"企业家A组"}> {'企业家A组'} </Radio>
            <Radio style={{ fontSize: "18px" }} key={'企业家B组'} value={'企业家B组'}> {'企业家B组'} </Radio>
            <Radio style={{ fontSize: "18px" }} key={'企业家C组'} value={'企业家C组'}> {'企业家C组'} </Radio>
            <Radio style={{ fontSize: "18px" }} key={"企业家D组"} value={'企业家D组'}> {'企业家D组'} </Radio>
          </Radio.Group>  
          <span style={{display:'none'}}>{info?.groupFlag}</span>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 3,
            span: 10,
          }}>
          <Button type="primary" htmlType="submit">
            确定
          </Button>
          <Button htmlType="button" onClick={onReset}>
            取消
          </Button>
        </Form.Item>
      </Form>

    </div>
  )
}
