import React, { useLayoutEffect, useRef, useState } from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { Menu, Layout } from "antd";
import img from '../../img/header.png'
import { createFromIconfontCN } from '@ant-design/icons';
import './index.scss'
const { Content, Sider } = Layout;

export default function Index(props) {

  // 选中
  const [xz, setXz] = useState('1')

  // icon
  const IconFont = createFromIconfontCN({ scriptUrl: '//at.alicdn.com/t/c/font_4294234_z0vjg7owd6n.js' });

  function getItem(label, key, icon, children,) {
    return { label, key, icon, children, };
  }
  const items = [
    getItem('课程管理', '1', <IconFont type={xz == '1' ? 'icon-kechengxuanzhong' : 'icon-kecheng'} />, [
      getItem('推荐课程', 'recommendedCourse'),
      getItem('入围课程', 'shortlistedCourse'),
    ]),
    getItem('账号管理', '2', <IconFont type={xz == '2' ? 'icon-zhanghaoxuanzhong' : 'icon-zhanghao'} />, [
      getItem('企业家账号', 'entrepreneur'),
      getItem('专家账号', 'expert'),
    ]),
  ];

  const items2 = [
    getItem('课程管理', '1', <IconFont type={xz == '1' ? 'icon-kechengxuanzhong' : 'icon-kecheng'} />, [
      getItem('推荐课程', 'recommendedCourse'),
    ]),
  ];
  // 点击当前菜单跳转页面
  const navigate = useNavigate();

  const clickMenu = (e) => {
    setXz(e.keyPath[1])

    if (e.item.props.ispopup) {
      navigate('masking', { state: { uuid: e.item.props.uuid } })
    } else {
      navigate(e.key);
    }
  };

  const dataScreenRef = useRef(null);
  useLayoutEffect(() => {
    setScale()
  }, []);
  const setScale = () => {
    let designWidth = 1920;//设计稿的宽度，根据实际项目调整
    let designHeight = 1080;//设计稿的高度，根据实际项目调整
    let scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designWidth / designHeight ?
      (document.documentElement.clientWidth / designWidth) :
      (document.documentElement.clientHeight / designHeight);
    if (dataScreenRef.current) {
      dataScreenRef.current.style.transform = `scale(${scale}) translate(-50%, -50%)`
    }
  }

  window.onresize = function () {
    setScale()
  }

  return (
    <div className="dataScreen" ref={dataScreenRef}>
      <div className='layout'>
        <header className='header'>
          <div className='headerImg'>
            <img src={img} alt="" />
          </div>
          <div className='tuiChu' onClick={() => {
            localStorage.removeItem('token'); localStorage.removeItem('name'); navigate('/')
          }}>
            退出
          </div>
        </header>
        <Layout /* className='layout' */ style={{ height: '90%' }}>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
            }}
            onCollapse={(collapsed, type) => {
            }}
          >
            <div className="logo" />
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={['recommendedCourse', '1']}
              defaultOpenKeys={['worksAdmin']}
              items={localStorage.getItem('name')=='admin'?items:items2}
              onClick={clickMenu}
            />
          </Sider>
          <Layout>
            <Content>
              <div style={{ padding: '37px ,0 ,0 54px' }}>
                <Outlet></Outlet>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </div>
  )
}
