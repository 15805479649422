import axios from 'axios'
import store from "../store/index";

const service = axios.create({
  // baseURL: 'https://qiyeweike.com',
  timeout: 10000, // request timeout
})

service.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token')
    return {
      ...config, headers: {
        ...config.headers,
        "accessToken": token,
        "token": token,
        "admin_uuid": token
      }
    };
  },
  error => {
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    return Promise.reject(error)
  },
)

export default service