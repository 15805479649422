/* 版权协议 */
import React from 'react'
import { useNavigate } from "react-router-dom";
import './index.scss'

export default function Copyright() {

    const navigate = useNavigate();
    return (
        <div className='copyright'>
            <h1>版权协议</h1>

            <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您（甲方）在本网站上传原创视频内容，即表示同意将该课程授予企业微课网站（乙方）公开发布、免费提供给中小企业学习使用的权利。双方经友好协商，达成如下协议:</h2>

            <h3>一、课程授权
                <p>1.1甲方同意将其拥有的网络视频课程授予乙方以公开发布、传播的权利。</p>
                <p>1.2乙方同意将严格按照公益性质，对甲方提供的课程进行传播。</p>
            </h3>

            <h3>二、授权范围
                <p>2.1甲方授权乙方有权使用网络视频课程的内容进行公开发布和传播。</p>
                <p>2.1甲方授权乙方有权使用网络视频课程的内容进行公开发布和传播。</p>
                <p>2.3乙方有权对网络视频课程进行必要的编辑和调整，以适应传播渠道和受众需求，但不得改变课程内容的核心和主题。</p>
            </h3>

            <h3>三、知识产权
                <p>3.1甲方保留网络视频课程的全部知识产权，包括但不限于著作权、商标权、专利权等。</p>
                <p>3.2甲方必须确保其拥有发布内容的全部原创版权，或已经获得版权所有者及相关法律的授权，且不得侵犯他人著作权，肖像权，商标权等，不违背商业保密协议。企业微课默认所有原创类的内容的发布者已经获得发表、演绎等版权授权。若因发布内容产生法律纠纷，全部责任在于用户本人，企业微课不承担任何法律责任</p>
                <p>3.3在未得到著作权人的授权时，也请不要将他人的作品全部或部分复制发表到企业微课，否则，用户应承担相应的不利后果，企业微课对此不承担任何责任。</p>
                <p>3.4第三方中小企业用户有下载、使用本站资料的权利。这里的使用指个人的、非商业性的使用。任何商业性的使用，必须征得本站或原作者的同意，或直接取得原作者的授权。</p>
                <p>3.5未经企业微课许可，任何人不得复制、转载、盗链、售卖企业微课上的任何资源，不得复制或仿造本站或者在非企业微课所属服务器上建立镜像。否则，企业微课将依法追究法律责任。</p>
                <p>3.6如果任何第三方侵犯了企业微课用户相关的权利，用户同意授权企业微课或其指定的代理人代表企业微课自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉（但非义务），或谈判和解，并且用户同意在企业微课认为必要的情况下参与共同维权。</p>
                <p>3.7企业微课的标识（包括但不限于企业微课文字、商标等）、源代码、及所有页面的版式设计等，版权归企业微课所有。未经授权，不得用于除企业微课之外的任何站点。</p>
            </h3>

            <h3>四、其他条款
                <p>4.1本合同未尽事宜，双方可通过补充协议进行约定，补充协议与本合同具有同等效力。</p>
            </h3>

            <h2>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本站可能修改某些条款。您应经常访问本页面以了解最新条款，因为这些条款与您密切相关。
                请自觉遵守上述声明。凡有侵权行为的个人、法人或其它组织，必须立即停止侵权并对其因侵权造成的一切后果担全部责任和相应赔偿。否则我们将依据中华人民共和国相关法律、法规追究其经济和法律责任。
            </h2>

            <div>
                <button onClick={()=>navigate('/login')}> 返回</button>
            </div>

        </div>
    )
}
