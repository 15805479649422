import React, { useLayoutEffect, useRef } from 'react'
import Register from "./re";
import login from "../../img/login.png";
import loginTitle from "../../img/loginTitile.png";
import "./index.scss";

const Login = () => {

	const dataScreenRef = useRef(null);
	useLayoutEffect(() => {
		setScale()
	}, []);
	const setScale = () => {
		let designWidth = 1920;//设计稿的宽度，根据实际项目调整
		let designHeight = 1080;//设计稿的高度，根据实际项目调整
		let scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designWidth / designHeight ?
			(document.documentElement.clientWidth / designWidth) :
			(document.documentElement.clientHeight / designHeight);
		if (dataScreenRef.current) {
			dataScreenRef.current.style.transform = `scale(${scale}) rotate(0.1deg) translate3d(-50%,-50%,0)`
			dataScreenRef.current.style.webkitTransform = `scale(${scale}) rotate(0.1deg) translate3d(-50%,-50%,0)`
			dataScreenRef.current.style.MozTransform = `scale(${scale}) rotate(0.1deg) translate3d(-50%,-50%,0)`
			dataScreenRef.current.style.msTransform = `scale(${scale}) rotate(0.1deg) translate3d(-50%,-50%,0)`
			dataScreenRef.current.style.OTransform = `scale(${scale}) rotate(0.1deg) translate3d(-50%,-50%,0)`
		}
	}

	window.onresize = function () {
		setScale()
	}

	return (
		// <div className="dataScreen" ref={dataScreenRef}>
		<div className="login-container" style={{ backgroundImage: `url(${login})` }}>
			<img src={loginTitle} alt="" />
			<div className="logins">
				<div className="Tabs">
					<Register></Register>
				</div>
			</div>
		</div>
		// </div>
	);
};

export default Login;


