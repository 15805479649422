/* 用户隐私政策 */
import React from 'react'
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {

    const navigate = useNavigate();
    return (
        <div className='copyright'>
            <h1>用户隐私政策</h1>

            <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您使用企业微课服务（下称：本服务），您在使用本服务前请认真阅读以下协议（下称：本协议）。</h2>

            <h3>一、企业微课使用协议的接受
                <p>当您在注册过程中在“已阅读并同意《用户隐私政策》”处打勾“ √ ”并按照注册程序成功注册为企业微课用户，或您以其他企业微课允许的方式实际使用企业微课服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与企业微课达成协议。</p>
            </h3>

            <h3>二、本协议的变更和修改
                <p>企业微课有权随时对本协议进行修改，并且一旦发生协议条款的变动，企业微课将在相关页面上提示修改的内容；用户如果不同意本协议的修改，可以放弃使用或访问本网站或取消已经获得的服务；如果用户选择在本协议变更后继续访问或使用本网站，则视为用户已经接受本协议的修改。</p>
            </h3>

            <h3>三、服务说明
                <p>1、企业微课是一个征集、将“名师优课”免费公开播出发布，并通过社交媒体平台等多种途径宣传推广，供广大中小企业免费学习使用的平台。</p>
                <p>2、企业微课运用自己的系统通过互联网向用户提供服务，除非另有明确规定，增加或优化目前服务的任何新功能，包括新产品以及新增加的服务，均无条件地适用本协议。</p>
            </h3>

            <h3>四、用户行为
                <p>用户需要实名认证并如实填写机构名称才能完成账号注册、正常使用网站提供的服务。用户必须承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。</p>
                <p>1、用户帐号、密码和安全</p>
                <p className='p1'>（1）您在注册账号时必须通过实名认证，才能成为企业微课的合法用户，得到一个密码和账号。您可以利用账户，通过本服务上传您的作品，合法正当地使用平台提供的各项服务功能。</p>
                <p className='p1'>（2）用户须对在企业微课的注册信息的真实性、合法性、有效性承担全部责任，用户不得使用他人的名义发布任何信息；当被发现用户冒用他人或机构的名义恶意注册帐号，企业微课有权立即停止提供服务，收回其帐号并由该用户承担由此而产生的一切法律及其他责任。</p>
                <p className='p1'>（3）您应采取合理措施维护其密码和帐号的安全。用户对利用该密码和帐号所进行的一切操作负全部责任；该操作导致的任何损失或损害由用户承担，企业微课不承担任何责任。</p>
                <p className='p1'>（4）用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户可以立即通知企业微课。</p>
                <p className='p1'>（5）对于用户长时间未使用的帐号，企业微课有权予以关闭。</p>

                <p>2、用户发布内容的传播</p>
                <p className='p1'>（1）您同意授权企业微课，有权通过本服务将您所上传的内容向全世界范围内不特定的网络用户展示。并授权企业微课以宣传为目的，在署名作者的前提下传播和使用您上传的内容。当任何第三方未经企业微课同意，转载、抓取、盗链包含用户内容在内的企业微课任何资料等行为时，用户同意并授权企业微课有权以企业微课的名义代表自己采取任何法律措施。</p>
                <p className='p1'>（2）如果您通过本服务分享作品，则您保证：</p>

                <p className='p3'>a 您所分享的作品均由您本人原创或已经依法获得原始权利人合法授权。</p>
                <p className='p3'>b 您有权授权企业微课依据本服务的相关规则使用您的作品，包括但不限于将您上传的作品向其他用户推荐或推广。</p>
                <p className='p3'>c 您所上传的作品符合国家相关法律法规和公序良俗，不会损害任何他方的合法权益或造成不良社会影响。</p>
                <p className='p3'>d 企业微课依照本服务的规则使用您的作品，无须另行获得第三方同意或对第三方承担任何责任。</p>

                <p>3、禁止用户从事以下行为：</p>
                <p className='p1'>（1）反对宪法所确定的基本原则的；</p>
                <p className='p1'>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                <p className='p1'>（3）损害国家荣誉和利益的；</p>
                <p className='p1'>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
                <p className='p1'>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                <p className='p1'>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                <p className='p1'>（7）散布淫秽、赌博、暴力、凶杀、自残、恐怖或者教唆犯罪的；</p>
                <p className='p1'>（8）侮辱或者诽谤他人，侵害他人合法权益（如名誉权、肖像权等）的；</p>
                <p className='p1'>（9）侵害他人知识产权、商业秘密等合法权利的；</p>
                <p className='p1'>（10）虚构事实、隐瞒真相以误导、欺骗他人的；</p>
                <p className='p1'>（11）发布、传送、传播广告信息及垃圾信息的；</p>
                <p className='p1'>（12）其他法律法规禁止的行为。</p>
                <p>如果您上传、发布或传输的内容含有以上违反法律法规的信息或内容，或者侵犯任何第三方的合法权益，您将直接承担以上导致的一切不利后果。</p>

                <p>4、用户对经由本网站张贴、发送电子邮件或传送的内容承担全部责任</p>
                <p className='p1'>（1）对于用户经由企业微课传送的内容，企业微课不保证其合法性、正当性、准确性、完整性或品质。用户在接受本网站服务后，在任何情况下，企业微课均不对任何内容承担任何责任，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。企业微课有权（但无义务）自行拒绝或删除经由本网站提供的任何内容。</p>
                <p className='p1'>（2）企业微课有权利在下述情况下，对内容进行保存或披露：</p>
                <p className='p3'>a 法律程序所规定；</p>
                <p className='p3'>b 本服使用协议规定；</p>
                <p className='p3'>c 被侵害的第三人提出权利主张；</p>
                <p className='p3'>d 为保护企业微课、其使用者及社会公众的权利、财产或人身安全；</p>
                <p className='p3'>e 其他企业微课认为有必要的情况。</p>

                <p>5、对用户发布信息的存储和披露</p>
                <p className='p1'>企业微课不对用户所发布信息的删除或储存失败承担责任。企业微课有权判断用户的行为是否符合本协议规定，如果企业微课认为用户违背了协议条款规定，有终止向其提供网站服务的权利。</p>

            </h3>

            <h3>五、关于隐私
                <p>1、当您注册使用本服务时，需要自愿提供个人信息。在您的同意及确认下，企业微课将通过注册表格等形式要求您提供一些个人资料，您有权拒绝提供这些信息</p>
                <p>2、在未经用户授权同意的情况下，企业微课不会公开、编辑或透露用户个人或企业信息，以下情况除外：</p>
                <p className='p1'>（1）根据执法单位的要求或为公共之目的向相关单位提供个人资料；</p>
                <p className='p1'>（2）由于用户将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露；</p>
                <p className='p1'>（3）由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被篡改等；</p>
                <p className='p1'>（4）由于与本网站链接的其他网站所造成的个人资料泄露及由此而导致的任何法律争议和后果；</p>
                <p className='p1'>（5）为免除访问者在生命、身体或财产方面之急迫危险；</p>
                <p className='p1'>（6）为维护企业微课公司的合法权益。</p>

                <p>3、在以下（包括但不限于）几种情况下，企业微课有权搜集、使用用户的个人信息，收集信息是为了向您提供更好、更优、更个性化的服务：</p>
                <p className='p1'>（1）所提供的资料将会被本网站统计、汇总，本网站会不定期地通过注册信息资料同用户保持联系。</p>
                <p className='p1'>（2）企业微课可以将用户信息与第三方数据匹配。</p>
                <p className='p1'>（3）企业微课会通过透露合计用户统计数据向第三方描述企业微课服务。</p>
                <p className='p1'>（4）企业微课所提供的服务会自动收集有关访问者的信息，这些信息包括访问者人数、访问时间、访问页面、来访地址等，企业微课使用这些信息来对我们的服务器进行分析和对网站进行管理。</p>
                <p className='p1'>（5）将遵循由中国广告协会互动网络分会颁布的《中国互联网定向广告用户信息保护框架标准》，基于合法、合理和诚实信用的法定原则妥善处理或使用用户的个人信息。</p>
            </h3>

            <h3>六、免责声明
                <p>1、除企业微课注明的服务条款外，其它因使用企业微课而导致任何意外、疏忽、合约毁坏、诽谤、版权或知识产权侵犯及其所造成的各种损失（包括因下载而感染电脑病毒），企业微课概不负责，亦不承担任何法律责任。</p>
                <p>2、企业微课不保证（包括但不限于）：</p>

                <p className='p1'>（1）本网站适合用户的使用要求；</p>
                <p className='p1'>（2）本网站不受干扰，及时、安全、可靠或不出现错误；</p>
                <p className='p1'>（3）用户经由本网站取得的任何产品、服务或其他材料符合用户的期望。</p>

                <p>3、本服务可能会包含与其他网站或资源的链接，即第三方链接。企业微课对于前述网站或资源的内容、隐私政策，无权控制、审查或修改，因而也不承担任何责任。建议您在离开企业微课服务，访问其他网站或资源前仔细阅读其服务条款和隐私政策。</p>
                <p>4、因用户内容在本网站的上传或发布，而导致任何第三方提出索赔要求或衍生的任何损害或损失，由用户承担全部责任，企业微课不承担任何责任。</p>
                <p>5、企业微课认为，一切网民在进入企业微课主页及各层页面时已经仔细看过本条款并完全同意。</p>
            </h3>

            <div>
                <button onClick={() => navigate('/login')}> 返回</button>
            </div>

        </div>
    )
}
