import http from "../index";

// 课程列表
export const listCourse = (params) => {
	return http.post(`/api/v1/admin/recommendCourseList`, params);
};

// * 课程类别
export const courseCategoryList = (params) => {
	return http.post(`/api/v1/admin/courseCategoryList`, params);
};

// 课程详情
export const infoCourse = (params) => {
	return http.post(`/api/v1/admin/courseInfo`, params);
};

// * 入围课程列表
export const winCourseList = (params) => {
	return http.post(`/api/v1/admin/winCourseList`, params);
};

// * 新增企业家
export const createOrg = (params) => {
	return http.post(`/api/v1/admin/createOrg`, params);
};

// * 删除企业家
export const delAuditUserOrg = (params) => {
	return http.post(`/api/v1/admin/delAuditUserOrg`, params);
};

// * 企业家列表
export const listOrg = (params) => {
	return http.post(`/api/v1/admin/listOrg`, params);
};

// * 修改企业家
export const editOrg = (params) => {
	return http.post(`/api/v1/admin/editOrg`, params);
};

// * 新增专家
export const createExpert = (params) => {
	return http.post(`/api/v1/admin/createExpert`, params);
};

// * 专家列表
export const listExpert = (params) => {
	return http.post(`/api/v1/admin/listExpert`, params);
};

// * 删除专家
export const delAuditUserExpert = (params) => {
	return http.post(`/api/v1/admin/delAuditUserExpert`, params);
};

// * 修改专家
export const editExpert = (params) => {
	return http.post(`/api/v1/admin/editExpert`, params);
};

// * 企业家/专家详情
export const auditUserInfo = (params) => {
	return http.post(`/api/v1/admin/auditUserInfo`, params);
};

// * 组课程分配
export const assignCourse = (params) => {
	return http.post(`/api/v1/admin/assignCourse `, params);
};

// * 课程上传
export const addWinCourse = (params) => {
	return http.post(`/api/v1/admin/addWinCourse`, params);
};



