/* 企业家 */
import React, { useState, useEffect } from 'react'
import { Input, Button, Table, message, Pagination, Select } from 'antd';
import { PlusOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { listOrg, delAuditUserOrg } from '../../../http/modules/organization'
import XinJian from './xinjian'
import './index.scss'
const { Search } = Input;

export default function Index() {

    // table 数据
    const [playerlist, setPlayerlist] = useState()
    // 修改的id
    const [playerId, setPlayerId] = useState()
    // 批量选中的 id
    const [arrId, setArrID] = useState()
    // 作品数量  分页器总数
    const [number, setNumber] = useState(0)
    // 分页器  当前页数
    const [current, setCurrent] = useState(1)
    // 搜索的内容
    const [searchTxt, setSearchTxt] = useState()
    // xinJian 名字
    const [name, setName] = useState()
    // 分组类别选择
    const [categorySelect, setCategorySelect] = useState()

    // table 选中
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setArrID(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    //企业家列表
    const OnListPlayer = async (current, searchTxt,categorySelect) => {
        try {
            const res = await listOrg({
                "pageNo": current,
                "pageSize": 10,
                "searchTxt": searchTxt,
                "group": categorySelect
            })
            let arr = []
            if (res.data?.list?.length) {
                res.data?.list.map((item) => {
                    arr.push(
                        {
                            key: item.uuid,
                            groupFlag: item.groupFlag,
                            phone: item.phone,
                            orgName: item.orgName,
                            loginName: item.loginName,
                            title: item.title,
                            name: item.name,
                            createTime: item.createTime,
                        },
                    )
                })
            }
            setCurrent(res.data.pageNo)
            setNumber(res.data.total)
            setSearchTxt(searchTxt)
            setPlayerlist(arr)
        } catch (error) {
            message.error(error.response?.data?.msg);
        }
    }

    // 删除
    const remove = async () => {
        if (!arrId) {
            message.error('请先选择')
            return
        }
        try {
            const res = await delAuditUserOrg({
                userUUIDList: arrId
            })
            if (res.code == 0) {
                message.success('删除成功')
                OnListPlayer(current, searchTxt,categorySelect)
            } else {
                message.error('删除失败')
            }
        } catch (error) {
            message.error(error.response?.data?.msg);
        }
    }

    // table 格式
    const columns = [
        {
            title: '组别',
            dataIndex: 'groupFlag',
            key: 'groupFlag',
            className: 'table'
        },
        {
            title: '用户名',
            dataIndex: 'loginName',
            key: 'loginName',
            className: 'table'
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            className: 'table'
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
            className: 'table'
        },
        {
            title: '单位',
            dataIndex: 'orgName',
            key: 'orgName',
            className: 'table'
        },
        {
            title: '注册时间',
            dataIndex: 'createTime',
            key: 'createTime',
            className: 'table'
        },
        {
            title: '操作',
            dataIndex: '',
            key: '',
            className: 'table',
            render: (event) => (
                <span style={{ color: '#1F78FE', cursor: 'pointer' }} onClick={() => { setPlayerId(event.key); setName('修改企业账户') }}>
                    修改
                </span>
            )
        }
    ];

    // 分页器点击回调
    const onPagination = (page) => {
        OnListPlayer(page, searchTxt,categorySelect)
    }

    useEffect(() => {
        OnListPlayer(1, '','')
    }, [])


    return (
        <>
            {playerId ? <XinJian name={name} playerId={playerId} setPlayerId={setPlayerId} OnListPlayer={OnListPlayer} /> :
                <div className='entrepreneur'>
                    <header>
                        <div className='p'>
                            <Input
                                placeholder="请输入企业家姓名或单位"
                                prefix={<SearchOutlined />}
                                value={searchTxt}
                                size="large"
                                onChange={(e) => setSearchTxt(e.target.value)}
                            />
                        </div>
                        <div className='p'>
                            组别：
                            <Select
                                value={categorySelect}
                                defaultValue="全部"
                                onChange={(value) => setCategorySelect(value)}
                                options={[
                                    { value: '全部', label: '全部' },
                                    { value: '企业家A组', label: '企业家A组' },
                                    { value: '企业家B组', label: '企业家B组' },
                                    { value: '企业家C组', label: '企业家C组' },
                                    { value: '企业家D组', label: '企业家D组' },
                                ]}
                            />
                        </div>

                        <div className='headerButton'>
                            <Button type="primary" onClick={() => { OnListPlayer(1, searchTxt, categorySelect) }} shape="round" icon={<SearchOutlined />} size={'large'}>
                                查询
                            </Button>
                            <Button onClick={remove} shape="round" icon={<DeleteOutlined />} size={'large'}>
                                删除
                            </Button>
                            <Button onClick={() => { setPlayerId('xinjian'); setName('新建企业账户') }} style={{ marginLeft: '20px' }} type="primary" shape="round" icon={<PlusOutlined />} size={'large'}>
                                新建
                            </Button>
                        </div>
                    </header>
                    <main>
                        <Table rowSelection={{
                            ...rowSelection,
                        }} dataSource={playerlist} columns={columns} pagination={false} />
                    </main>
                    <footer>
                        <Pagination onChange={onPagination} showSizeChanger={false} total={number} current={current} pageSize={10} />
                    </footer>
                </div>
            }
        </>
    )
}

