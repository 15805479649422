/* 推荐课程 */
import React, { useState, useEffect } from 'react'
import { Input, Button, Table, message, Select, Pagination, Spin } from 'antd';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { listCourse, courseCategoryList } from "../../../http/modules/organization";
import ReviewWorks from '../reuse/reviewWorks'
import Allot from './allot';
import './index.scss'


export default function Index() {

    // 审核作品页面展示
    const [reviewWorks, setReviewWorks] = useState(false)
    // 参赛作品id
    const [playerId, setPlayerId] = useState()
    //全部课程类别
    const [categoryList, setCategoryList] = useState([])
    // 作品数量  分页器总数
    const [number, setNumber] = useState(0)
    // 分页器  当前页数
    const [current, setCurrent] = useState(1)
    // 课程类别选择
    const [categorySelect, setCategorySelect] = useState()
    // 搜索的内容
    const [searchName, setSearchName] = useState()
    // 搜索的课程质量
    const [rate, setRate] = useState()
    // 搜索的分配状态
    const [assignState, setAssignState] = useState()
    // loding
    const [loading, setLoading] = useState(false)
    // table 数据
    const [playerlist, setPlayerlist] = useState([])
    // 选中的课
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    // 教学效果成绩排序
    const [sortAvgScoreOrgEffect, setSortAvgScoreOrgEffect] = useState('')
    // 总成绩排序
    const [sortTotalScore, setSortTotalScore] = useState('')
    // 当前年份
    const [currentYear, setcurrentYear] = useState();
    // 搜多的年份
    const [searchYear, setSearchYear] = useState('');

    const [open, setOpen] = useState(false);

    // Table 选中
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    //处理 下拉框数据
    const fold = (arr) => {
        let items = [
            {
                key: 1,
                label: (
                    <p className='p2' style={{ background: '#F3F9FF' }}>
                        <span>组别</span><span>评审家</span><span>评审成绩</span>
                    </p>
                )
            }
        ]
        arr?.map((i, index) => {
            items.push({
                key: index + 2,
                label: (
                    <div className='p2'>
                        <span>{i.group}</span><span>{i.person}</span><span>{i.score == 0 ? '/' : i.score}</span>
                    </div>
                ),
            })
        })
        return items
    }

    // table格式
    const columns = [
        {
            title: '课程名称',
            dataIndex: 'title',
            key: 'title',
            className: 'table',
            // ellipsis: true,
        },

        {
            title: '课程质量',
            dataIndex: 'rate',
            key: 'rate',
            className: 'table',
            ellipsis: true,
            render: (text) => (
                <span> {text == 'A' ? '优秀' : '良好'} </span>
            )
        },
        {
            title: '课程类别',
            dataIndex: 'category',
            key: 'category',
            className: 'table',
            ellipsis: true,
            width: 110
        },
        {
            title: '地区',
            dataIndex: '',
            key: '',
            className: 'table',
            render: (e) => <>{e.province}{e.city}{e.county}</>,
        },
        {
            title: '机构',
            dataIndex: 'orgName',
            key: 'orgName',
            className: 'table',
        },
        {
            title: '评审企业组',
            dataIndex: 'groupOrg',
            key: 'groupOrg',
            className: 'table',
            ellipsis: true,
            width: 110,
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    placement={'bottomRight'}
                    options={fold(record.groupOrgList)}
                    showArrow={false}
                    bordered={false}
                />
            )
        },
        {
            title: '教学效果成绩',
            dataIndex: 'avgScoreOrgEffect',
            key: 'avgScoreOrgEffect',
            className: 'table',
            width: 135,
            render: (text) => (
                <span> {text == '0' ? '/' : text} </span>
            ),
        },
        {
            title: '企业组评审成绩',
            dataIndex: 'avgScoreOrg',
            key: 'avgScoreOrg',
            className: 'table',
            width: 130,
            render: (text) => (
                <span> {text == '0' ? '/' : text} </span>
            )
        },
        {
            title: '评审专家组',
            dataIndex: 'groupExpert',
            key: 'groupExpert',
            className: 'table',
            width: 105,
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    placement={'bottomRight'}
                    options={fold(record.groupExpertList)}
                    showArrow={false}
                    bordered={false}
                />
            )
        },
        {
            title: '专家组评审成绩',
            dataIndex: 'avgScoreExpert',
            key: 'avgScoreExpert',
            className: 'table',
            width: 130,
            render: (text) => (
                <span> {text == '0' ? '/' : text} </span>
            )
        },
        {
            title: '公众投票',
            dataIndex: 'scoreZan',
            key: 'scoreZan',
            className: 'table',
            width: 100
        },
        {
            title: '总成绩',
            dataIndex: 'totalScore',
            key: 'totalScore',
            className: 'table',
            width: 90,
        },
        {
            title: '操作',
            dataIndex: '',
            key: '',
            width: 90,
            className: 'table',
            render: (event) => (
                <span style={{ color: '#1F78FE', cursor: 'pointer' }} onClick={() => { setPlayerId(event.uuid); setReviewWorks(true) }}>
                    查看
                </span>
            )
        }
    ];

    // 分页器点击回调
    const onPagination = (page) => {
        onListCourse(page, categorySelect, searchName, rate, assignState)
    }

    // 课程列表
    const onListCourse = async (page, category = '', searchTxt = '', rate = '', assignState = '', sortAvgScoreOrgEffect = '', sortTotalScore = '') => {
        setLoading(true)
        try {
            const res = await listCourse({
                "pageNo": page,
                "pageSize": 10,
                "category": category, // 课程类别
                "searchTxt": searchTxt, // 课程名称 授课人 申报单位 搜索
                "rate": rate,
                "assignState": assignState,
                "sortAvgScoreOrgEffect": sortAvgScoreOrgEffect, // 升序 ASC 降序 DESC 空字符串 本字段不做排序
                "sortTotalScore": sortTotalScore,
                "year":searchYear
            });
            if (res.code == 0) {
                let arr = []
                if (res.data?.list?.length) {
                    res.data?.list.map((item) => {
                        arr.push(
                            {
                                key: item.uuid,
                                ...item
                            },
                        )
                    })
                }
                setCurrent(res.data.pageNo)
                setNumber(res.data.total)
                setPlayerlist(arr)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            message.error(err.response?.data?.msg);
        }
    };

    //课程类别
    const onCourseCategoryList = async () => {
        try {
            const res = await courseCategoryList({})
            if (res.code == 0) {
                let arr = res.data?.list
                arr.unshift({ name: '全部', pinyin: '' })
                setCategoryList(arr)
            }
        } catch (err) {
            message.error(err.response?.data?.msg);
        }
    }

    useEffect(() => {
        onCourseCategoryList()
        onListCourse(1)
        const now = new Date();
        setcurrentYear(now.getFullYear())
    }, [])

    return (
        <Spin spinning={loading}>
            {reviewWorks ? <ReviewWorks setReviewWorks={setReviewWorks} reviewWorksId={playerId} /> :
                <div className='recommendedCourse'>
                    <header>
                        <div className='p'>
                            <span className='text'>课程类别：</span>
                            <Select
                                defaultValue="全部"
                                value={categorySelect}
                                onChange={(value) => { setCategorySelect(value) }}
                                options={categoryList.map(item => ({ value: item.name, label: item.name }))}
                            />
                        </div>
                        <div className='p'>
                            <span className='text'>课程质量：</span>
                            <Select
                                defaultValue="全部"
                                value={rate}
                                onChange={(value) => { setRate(value) }}
                                options={[
                                    { value: '全部', label: '全部' },
                                    { value: '优秀', label: '优秀' },
                                    { value: '良好', label: '良好' },
                                ]}
                            />
                        </div>
                        <div className='p'>
                            <span className='text'>分配状态：</span>
                            <Select
                                defaultValue="全部"
                                value={assignState}
                                onChange={(value) => { setAssignState(value) }}
                                options={[
                                    { value: '全部', label: '全部' },
                                    { value: '已分配', label: '已分配' },
                                    { value: '未分配', label: '未分配' },
                                ]}
                            />
                        </div>
                        <div className='p'>
                            <span className='text'>搜索内容：</span>
                            <Input
                                placeholder="请输入课程名称/组别 ！"
                                prefix={<SearchOutlined />}
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                                size="large"
                            />
                        </div>
                        <div className='p'>
                            <span className='text'>教学效果：</span>
                            <Select
                                defaultValue="不排序"
                                value={sortAvgScoreOrgEffect}
                                onChange={(value) => { setSortAvgScoreOrgEffect(value) }}
                                options={[
                                    { value: '', label: '不排序' },
                                    { value: 'ASC', label: '升序' },
                                    { value: 'DESC', label: '倒序' },
                                ]}
                            />
                        </div>
                        <div className='p'>
                            <span className='text'>总成绩：</span>
                            <Select
                                defaultValue="不排序"
                                value={sortTotalScore}
                                onChange={(value) => { setSortTotalScore(value) }}
                                options={[
                                    { value: '', label: '不排序' },
                                    { value: 'ASC', label: '升序' },
                                    { value: 'DESC', label: '倒序' },
                                ]}
                            />
                        </div>
                        <div className='p'>
                            <span className='text'>年份：</span>
                            <Select
                                defaultValue="全部"
                                value={searchYear}
                                onChange={(value) => { setSearchYear(value) }}
                                options={[
                                    { value: '', label: '全部' },
                                    { value: `${currentYear}`, label: `${currentYear}`, },
                                    { value: `${currentYear - 1}`, label: `${currentYear - 1}` }
                                ]}
                            />
                        </div>

                        <div className='p'>
                            <Button type="primary" onClick={() => { onListCourse(1, categorySelect, searchName, rate, assignState, sortAvgScoreOrgEffect, sortTotalScore) }} shape="round" icon={<SearchOutlined />} size={'large'}>
                                查询
                            </Button>
                            <Button type="primary" onClick={() => { setOpen(true) }} icon={<UndoOutlined />} size={'large'}>
                                分配
                            </Button>
                        </div>
                    </header>
                    <div className='tongJi'>
                        <p>共有 <span>{number}</span> 个课程</p>
                    </div>
                    <main>
                        <Table rowSelection={rowSelection} dataSource={playerlist} columns={columns} pagination={false} scroll={{ x: 'max-content' }}/>
                    </main>
                    <footer>
                        <Pagination onChange={onPagination} showSizeChanger={false} total={number} current={current} pageSize={10} />
                    </footer>
                    <Allot setSelectedRowKeys={setSelectedRowKeys} setOpen={setOpen} open={open}
                        selectedRowKeys={selectedRowKeys} onListCourse={onListCourse} current={current}
                        categorySelect={categorySelect} searchName={searchName}
                        rate={rate} assignState={assignState}
                    ></Allot>
                </div>
            }
        </Spin>
    )
}
