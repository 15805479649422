import React from "react";
import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { unionLogin } from "../../http/modules/login";
import { useDispatch } from 'react-redux'
import { setToken } from '../../store/storeLogin'
import { Tabs } from "antd";
import './index.scss'


function ShengTing(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    // 登录
    const unionOnFinish = async (values) => {
        try {
            const res = await unionLogin({
                loginKey: values.loginKey,
                password: values.password,
            });
            if (res.code == 0) {
                localStorage.setItem('token', res.data.accessToken)
                localStorage.setItem('name', res.data.admin.name)
                dispatch(setToken(res.data.accessToken))
                message.success("登录成功！");
                navigate("/organization/recommendedCourse");
            } else {
                message.error(res.msg);
            }
        } catch (error) {
            message.error(error.response?.data?.msg);
        }
    };

    return (
        <div>
            <Form
                name="basic"
                onFinish={unionOnFinish}
                autoComplete="off"
                labelAlign={"left"}
            >
                <Form.Item
                    name="loginKey"
                    rules={[{ required: true, message: '请输入用户名!' }]}
                >
                    <Input placeholder="用户名" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入正确密码!' }]}
                >
                    <Input.Password placeholder="密码" />
                </Form.Item>

                <Form.Item className="name" style={{ marginTop: '40px' }}>
                    <Button
                        style={{ width: "419px", height: "56px", borderRadius: "10.5px", transition: "unset", fontSize: "22px" }}
                        type="primary"
                        htmlType="submit"
                    >
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

function Register() {

    const items = [
        {
            key: "2",
            label: `登录`,
            children: <ShengTing />
        }
    ];

    return (
        <div className="register">
            <Tabs items={items} />
        </div>
    );
}

export default Register
