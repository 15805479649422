import React from 'react';
import { useRoutes } from 'react-router-dom';
import Login from '../views/login'
// admin
import Organization from '../views/organization/index'
import RecommendedCourse from '../views/province/recommendedCourse'
import ShortlistedCourse from '../views/province/shortlistedCourse'
import Expert from '../views/accountManagement/expert'
import Entrepreneur from '../views/accountManagement/entrepreneur'

//协议
import Copyright from '../views/agreement/copyright.jsx'
import PrivacyPolicy from '../views/agreement/privacyPolicy.jsx'
// 不存在
import NotFound from '../views/notFound/index'


const IndexRouter = [
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/copyright",
    element: <Copyright />
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyPolicy />
  },
  {
    path: "/organization",
    element: <Organization />,
    children: [
      {
        path:'/organization/recommendedCourse',
        element:<RecommendedCourse/>
      },
      {
        path:'/organization/shortlistedCourse',
        element:<ShortlistedCourse/>
      },
      {
        path:'/organization/expert',
        element:<Expert/>
      },
      {
        path:'/organization/entrepreneur',
        element:<Entrepreneur/>
      },
    ]
  },
  {
    path: "*",
    element: <NotFound />
  },
]

const Router = () => {
  const routes = useRoutes(IndexRouter);
  return routes;
};

export default Router;
