/* 审核作品 */
import React, { useState, useEffect } from 'react'
import { infoCourse } from "../../../http/modules/organization";
import { Form, Input, Button, message, } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import './index.scss'

export default function ReviewWorks(props) {

    const { reviewWorksId, setReviewWorks } = props
    // 课程详情
    const [course, setCourse] = useState()
    // 报名name
    const [baoMingName, setBaoMingName] = useState()
    // 附件
    const [fujianData, setFujianData] = useState()

    // 课程详情
    const onListCourse = async () => {
        try {
            const res = await infoCourse({
                "courseUUID": reviewWorksId
            });
            if (res.code == 0) {
                let arr = []
                arr.push({
                    name: res.data.formName,
                    url: res.data.formUrl
                })
                setBaoMingName(arr)
                setFujianData(res.data.attachmentList)
                setCourse(res.data)
            }
        } catch (err) {
            message.error(err.response?.data?.msg);
        }
    };

    useEffect(() => {
        onListCourse()
    }, [])

    return (
        <div className='reviewWorks'>
            <header>
                <p></p>
                作品详情
            </header>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 18,
                }}
                layout="horizontal"
            >

                <Form.Item
                    label="授课人"
                >
                    {course ? <Input defaultValue={course?.playerName} disabled={true} /> : ''}
                </Form.Item>

                <Form.Item label="课程名称" name='title'>
                    {course ? <Input defaultValue={course?.title} disabled={true} /> : ''}
                </Form.Item>

                <Form.Item label="课程类别" name='category'>
                    {course ? <Input defaultValue={course?.title} disabled={true} /> : ''}
                </Form.Item>

                <Form.Item label="授课类型" name='typ'>
                    {
                        course ? <Input defaultValue={course?.typ} disabled={true} /> : ''
                    }
                </Form.Item>

                <Form.Item wrapperCol={{
                    offset: 0,
                    span: 14,
                }} label="课程视频" valuePropName="fileList">
                    <>
                        {course ? <div className='video'>
                            <video src={course?.mainCourseUrl?.split("?")[0]} controls width={430} height={200}></video>
                            <span title={course.mainCourseName}> 课程名称：{course.mainCourseName}</span>
                        </div> : ''}
                        {course?.typ == '标准课程' ? <div className='video'>
                            <video src={course?.subCourseUrl?.split("?")[0]} controls width={430} height={200}></video>
                            <span title={course.subCourseName}> 课程名称：{course.subCourseName}</span>
                        </div> : ''}
                    </>
                </Form.Item>

                <Form.Item label="上传附件" valuePropName="fileList">
                    <div className='fujian'>
                        {baoMingName?.map((item) => {
                            return <p style={{ width: '100%' }}>
                                <LinkOutlined className='icon' /> <a target="_blank" href={item.url} download>{item.name}</a>
                            </p>
                        })}
                        {fujianData?.map((item) => {
                            return <p style={{ width: '100%' }}>
                                <LinkOutlined className='icon' /> <a target="_blank" href={item.url} download>{item.name}</a>
                            </p>
                        })}
                    </div>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 4,
                        span: 6,
                    }}>
                    <Button onClick={() => setReviewWorks(false)} type="primary" htmlType="submit" className='quXiao'>
                        返回
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}
