/* 入围课程 */
import React, { useState, useEffect } from 'react'
import { Input, Button, Table, message, Select, Pagination, Spin } from 'antd';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { winCourseList, courseCategoryList } from "../../../http/modules/organization";
import ReviewWorks from '../reuse/reviewWorks'
import ApplyAdd from '../../organization/apply/applyAdd';
import './index.scss'
const { Search } = Input;


export default function Index() {

    // 审核作品页面展示
    const [reviewWorks, setReviewWorks] = useState(false)
    // 参赛作品id
    const [playerId, setPlayerId] = useState()
    //全部课程类别
    const [categoryList, setCategoryList] = useState([])
    // 作品数量  分页器总数
    const [number, setNumber] = useState(0)
    // 分页器  当前页数
    const [current, setCurrent] = useState(1)
    // 课程类别选择
    const [categorySelect, setCategorySelect] = useState()
    // 搜索的内容
    const [searchName, setSearchName] = useState()
    // loding
    const [loading, setLoading] = useState(false)
    // table 数据
    const [playerlist, setPlayerlist] = useState([])
    // 当前年份
    const [currentYear, setcurrentYear] = useState();
    // 搜索的年份
    const [searchYear, setSearchYear] = useState('');

    const [open, setOpen] = useState(false);

    // 下拉课程类别
    const handleChange = (value) => {
        setCategorySelect(value)
    };

    // Table 选中
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    // table格式
    const columns = [
        {
            title: '课程名称',
            dataIndex: 'title',
            key: 'title',
            className: 'table',
            ellipsis: true,
        },
        {
            title: '授课人',
            dataIndex: 'playerName',
            key: 'playerName',
            className: 'table',
            ellipsis: true,
            width: 90
        },
        {
            title: '课程类别',
            dataIndex: 'category',
            key: 'category',
            className: 'table',
            ellipsis: true,
            width: 110
        },
        {
            title: '评审企业组',
            dataIndex: 'groupOrg',
            key: 'groupOrg',
            className: 'table',
            width: 105
        },
        {
            title: '教学效果成绩',
            dataIndex: 'avgScoreOrgEffect',
            key: 'avgScoreOrgEffect',
            className: 'table'
        },
        {
            title: '企业组评审成绩',
            dataIndex: 'avgScoreOrg',
            key: 'avgScoreOrg',
            className: 'table',
            width: 130,
        },
        {
            title: '评审专家组',
            dataIndex: 'groupExpert',
            key: 'groupExpert',
            className: 'table',
            width: 105
        },
        {
            title: '专家组评审成绩',
            dataIndex: 'avgScoreExpert',
            key: 'avgScoreExpert',
            className: 'table',
            width: 130,
        },
        {
            title: '公众投票',
            dataIndex: 'scoreZan',
            key: 'scoreZan',
            className: 'table',
            width: 100
        },
        {
            title: '总成绩',
            dataIndex: 'totalScore',
            key: 'totalScore',
            className: 'table',
            width: 90
        },
        {
            title: '操作',
            dataIndex: '',
            width: 90,
            key: '',
            className: 'table',
            render: (event) => (
                <span style={{ color: '#1F78FE', cursor: 'pointer' }} onClick={() => { setPlayerId(event.uuid); setReviewWorks(true) }}>
                    查看
                </span>
            )
        }
    ];

    // 分页器点击回调
    const onPagination = (page) => {
        onListCourse(page, categorySelect, searchName)
    }

    // 课程列表
    const onListCourse = async (page, category = '', searchTxt = '') => {
        setLoading(true)
        try {
            const res = await winCourseList({
                "pageNo": page,
                "pageSize": 10,
                "category": category, // 课程类别
                "searchTxt": searchTxt, // 课程名称 授课人 申报单位 搜索
                "year":searchYear
            });
            if (res.code == 0) {
                setCurrent(res.data.pageNo)
                setNumber(res.data.total)
                setPlayerlist(res.data.list)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            message.error(err.response?.data?.msg);
        }
    };

    //课程类别
    const onCourseCategoryList = async () => {
        try {
            const res = await courseCategoryList({})
            setCategoryList(res.data?.list)
        } catch (error) {
            message.error(error.response?.data?.msg);
        }
    }

    useEffect(() => {
        onCourseCategoryList()
        onListCourse(1)
        const now = new Date();
        setcurrentYear(now.getFullYear())
    }, [])

    return (
        <Spin spinning={loading}>
            {open ? <ApplyAdd setOpen={setOpen} /> : reviewWorks ? <ReviewWorks setReviewWorks={setReviewWorks} reviewWorksId={playerId} /> :
                <div className='shortlistedCourse'>
                    <header>
                        <div className='p'>
                            <Input
                                placeholder="请输入课程名称/授课人/申报单位"
                                prefix={<SearchOutlined />}
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                                size="large"
                            />
                        </div>
                        <div className='p'>
                            课程类别：
                            <Select
                                value={categorySelect}
                                defaultValue="全部"
                                onChange={handleChange}
                                options={categoryList.map(item => ({ value: item.name, label: item.name }))}
                            />
                        </div>
                        <div className='p'>
                            <span className='text'>年份：</span>
                            <Select
                                defaultValue="全部"
                                value={searchYear}
                                onChange={(value) => { setSearchYear(value) }}
                                options={[
                                    { value: '', label: '全部' },
                                    { value: `${currentYear}`, label: `${currentYear}`, },
                                    { value: `${currentYear - 1}`, label: `${currentYear - 1}` }
                                ]}
                            />
                        </div>
                        <div className='p'>
                            <Button type="primary" onClick={() => { onListCourse(1, categorySelect, searchName) }} shape="round" icon={<SearchOutlined />} size={'large'}>
                                查询
                            </Button>
                            <Button type="primary" onClick={() => { setOpen(true) }} icon={<UndoOutlined />} size={'large'}>
                                新建
                            </Button>
                        </div>
                    </header>
                    <div className='tongJi'>
                        <p>共有 <span>{number}</span> 个课程</p>
                    </div>
                    <main>
                        <Table rowSelection={{ ...rowSelection, }}
                            dataSource={playerlist} columns={columns} pagination={false} />
                    </main>
                    <footer>
                        <Pagination onChange={onPagination} showSizeChanger={false} total={number} current={current} pageSize={12} />
                    </footer>
                </div>
            }
        </Spin>
    )
}

